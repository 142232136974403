import { useCallback, useState, useEffect } from 'react'

type FetchRequestInitialState = {
  state: 'initial'
}

type FetchRequestLoadingState = {
  state: 'loading'
}

type FetchRequestSuccessState<P, T> = {
  state: 'success'
  payload: P
  data: T
  statusCode: number
  result: string // Add this line
}

type FetchRequestErrorState = {
  state: 'error'
  error: Error
}

export type FetchRequestState<P, R> =
  | FetchRequestInitialState
  | FetchRequestLoadingState
  | FetchRequestErrorState
  | FetchRequestSuccessState<P, R>

export const isInitial = <P, R>(
  s: FetchRequestState<P, R>
): s is FetchRequestInitialState => s.state === 'initial'

export const isLoading = <P, R>(
  s: FetchRequestState<P, R>
): s is FetchRequestLoadingState => s.state === 'loading'

export const isSuccess = <P, R>(
  s: FetchRequestState<P, R>
): s is FetchRequestSuccessState<P, R> => s.state === 'success'

export const isError = <P, R>(
  s: FetchRequestState<P, R>
): s is FetchRequestErrorState => s.state === 'error'

type Method = 'GET' | 'PUT' | 'DELETE' | 'POST'

type MakeRequest<P, R> = (
  path: string,
  method: Method,
  payload: P,
  onSuccess?: (data: R) => void
) => void

export type UseFetchTuple<P, R> = [MakeRequest<P, R>, FetchRequestState<P, R>]

const useFetch = <P, R>(deps: unknown[]): UseFetchTuple<P, R> => {
  const [state, setState] = useState<FetchRequestState<P, R>>({
    state: 'initial',
  })

  useEffect(() => {
    setState({ state: 'initial' })
  }, deps ?? [])

  const makeRequest: MakeRequest<P, R> = useCallback(
    (path, method, payload, onSuccess) => {
      if (isLoading(state)) {
        return
      }

      setState({
        state: 'loading',
      })

      fetch(path, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        ...(payload && method !== 'GET'
          ? {
              body: JSON.stringify(payload),
            }
          : {}),
      })
        .then(async (res) => {
          const data = res.status === 204 ? undefined : await res.json()

          if ([200, 201, 204].includes(res.status)) {
            onSuccess?.(data)
            setState({
              state: 'success',
              payload,
              data,
              statusCode: res.status,
              result: 'success',
            })
            return
          }

          setState({
            state: 'error',
            error: new Error('Ocorreu um erro inesperado'),
          })
        })
        .catch((error) => {
          setState({
            state: 'error',
            error,
          })
        })
    },
    [state, setState]
  )

  return [makeRequest, state]
}

export default useFetch
