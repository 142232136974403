import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Alert from '@material-ui/lab/Alert'
import React, { useEffect, useState } from 'react'

import * as m from '@jogahq/lib/src/maybe'
import { useThemeContext } from '../../../../web-app/context/ThemeContext'

export enum AuthError {
  NOT_FOUND = 'not-found',
  NOT_ALLOWED = 'not-allowed',
  PASSWORD_MISSMATCH = 'password-missmatch',
}

// Rename and update images to defaultImages
const defaultImages = [
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined.jpg',
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined.jpg',
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined.jpg',
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined2.jpg',
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined2.jpg',
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined2.jpg',
  'https://jogamais-images.s3.us-west-2.amazonaws.com/escolinhas/background+combined2.jpg',
]

// Add subdomain-specific images
const subdomainImages: Record<string, string[]> = {
  'furacao': [
    '/images/furacao/caldeirao.jpg',
    '/images/furacao/estadio.jpeg',
    '/images/furacao/furacao_bandeira-destaque.JPG',
    '/images/furacao/furacao_bandeira.jpeg',
    '/images/furacao/iluminacao-noturna-ligga-arena.jpg',
    '/images/furacao/TunelLiggaArena2024-1200-1024x427.jpg',
    '/images/furacao/vestiarios.jpg',
  ],
  'proeex': [
    'https://jogamais-images.s3.us-west-2.amazonaws.com/proeex/login-bg-1.jpg',
    'https://jogamais-images.s3.us-west-2.amazonaws.com/proeex/login-bg-2.jpg',
  ],
}

// Update getRandomImage function
function getRandomImage(subdomain: string | null) {
  if (subdomain && subdomainImages[subdomain]) {
    const subdomainImagesArray = subdomainImages[subdomain];
    return subdomainImagesArray[Math.floor(Math.random() * subdomainImagesArray.length)];
  }
  return defaultImages[Math.floor(Math.random() * defaultImages.length)];
}

interface Props {
  email: string
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
  password: string
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSignIn: React.FormEventHandler<HTMLFormElement>
  error: m.Maybe<AuthError>
  loading: boolean
}

const SignInLayout: React.FC<Props> = (props) => {
  const { currentSubdomain } = useThemeContext();
  const [backgroundImage, setBackgroundImage] = useState('');
  
  useEffect(() => {
    setBackgroundImage(getRandomImage(currentSubdomain));
  }, [currentSubdomain]);

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    alert: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    image: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }))
  
  const classes = useStyles()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.onSignIn(e)
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Autenticar
          </Typography>

          {props.error === AuthError.NOT_ALLOWED && (
            <Alert severity="error" className={classes.alert}>
              Permissão negada
            </Alert>
          )}
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={props.email}
              onChange={props.onChangeEmail}
              error={props.error === AuthError.NOT_FOUND}
              helperText={
                props.error === AuthError.NOT_FOUND
                  ? 'Email não encontrado'
                  : undefined
              }
              disabled={props.loading}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={props.password}
              onChange={props.onChangePassword}
              error={props.error === AuthError.PASSWORD_MISSMATCH}
              helperText={
                props.error === AuthError.PASSWORD_MISSMATCH
                  ? 'Senha inválida'
                  : undefined
              }
              disabled={props.loading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={props.loading}
              startIcon={
                props.loading ? (
                  <CircularProgress size={16} color="primary" />
                ) : undefined
              }
            >
              Entrar
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default SignInLayout
