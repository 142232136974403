type None = null | undefined

export type Maybe<T> = T | None

export const isNone = <T>(m: Maybe<T>): m is None =>
  m === undefined || m === null

export const isSome = <T>(m: Maybe<T>): m is T => !isNone(m)

export const none = <T>(): Maybe<T> => null

export const some = <T>(v: T): Maybe<T> => v

export const fromNullable = <T>(v: T | null | undefined): Maybe<T> =>
  v === undefined || v === null ? none() : some(v)

export const map = <A, B>(
  mapper: (a: A) => B | Maybe<B>,
  maybe: Maybe<A>
): Maybe<B> => {
  if (isSome(maybe)) {
    return some(mapper(maybe))
  }

  return none()
}

export const mapP = <A, B>(
  mapper: (a: A) => Promise<B | Maybe<B>>,
  maybe: Maybe<A>
): Promise<Maybe<B>> => {
  if (isSome(maybe)) {
    return mapper(maybe)
  }

  return Promise.resolve(none())
}

export const orElse = <T>(elseValue: T, m: Maybe<T>): T =>
  isSome(m) ? m : elseValue

export const fold = <A, B>(
  onNone: () => B,
  onSome: (a: A) => B,
  maybe: Maybe<A>
) => {
  if (isSome(maybe)) {
    return onSome(maybe)
  }

  return onNone()
}

export const foldP = <A, B>(
  onNone: () => Promise<B>,
  onSome: (a: A) => Promise<B>,
  maybe: Maybe<A>
): Promise<B> => {
  if (isSome(maybe)) {
    return onSome(maybe)
  }

  return onNone()
}

export const toList = <T>(maybe: Maybe<T[]> | Maybe<T>): T[] => {
  if (isNone(maybe)) {
    return []
  }

  if (Array.isArray(maybe)) {
    return maybe
  }

  return [maybe]
}

export const catMaybes = <T>(arr: Maybe<T>[]): T[] =>
  arr.reduce((arr: T[], a) => (isSome(a) ? arr.concat([a]) : arr), [])

export const filter = <T>(
  predicate: (t: T) => boolean,
  maybe: Maybe<T>
): Maybe<T> =>
  fold(
    () => none(),
    (t) => (predicate(t) ? t : none()),
    maybe
  )

export function fromSome(expiresAt: Maybe<Date>): number | Date {
  if (isSome(expiresAt)) {
    return expiresAt
  }
  throw new Error('Function not implemented.')
}

export function unwrap(expiresAt: Maybe<Date>): number | Date {
  if (isSome(expiresAt)) {
    return expiresAt
  }
  throw new Error('Function not implemented.')
}

export function fromString(value: string | null | undefined): Maybe<string> {
  return value ? some(value) : none()
}

export function fromMaybe<T>(maybe: Maybe<T>, defaultValue: T): T {
  return isSome(maybe) ? maybe : defaultValue
}
