import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface ThemePalette {
  common: { black: string; white: string };
  background: { paper: string; default: string };
  primary: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  secondary: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  error: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  };
}

interface Theme {
  palette: ThemePalette;
}

interface ThemeContextState {
  customTheme: Theme;
  setCustomTheme: (value: Theme) => void;
  currentSubdomain: string | null;
}

// Create the context with undefined as default - this forces consumers to use the provider
const ThemeContext = createContext<ThemeContextState | undefined>(undefined);

// Define subdomain themes
const subdomainThemes: Record<string, Theme> = {
  'furacao': {
    palette: {
      common: { black: 'rgba(26, 33, 58, 1)', white: '#fff' },
      background: { paper: '#fff', default: '#f5f5f5' },
      primary: {
        light: 'rgba(227, 79, 103, 1)', // Lighter version of the primary color
        main: 'rgba(200, 16, 46, 1)',   // The exact RGB (200, 16, 46) converted to rgba
        dark: 'rgba(170, 14, 39, 1)',   // Darker version of the primary color
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(69, 63, 59, 1)',   // Lighter version of the secondary color
        main: 'rgba(45, 41, 38, 1)',    // The exact RGB (45, 41, 38) converted to rgba
        dark: 'rgba(30, 27, 25, 1)',    // Darker version of the secondary color
        contrastText: '#fff',
      },
      error: {
        light: 'rgba(230, 84, 84, 1)',
        main: 'rgba(254, 57, 57, 1)',
        dark: 'rgba(218, 51, 51, 1)',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(26, 33, 58, 0.95)',
        secondary: 'rgba(26, 33, 58, 0.61)',
        disabled: 'rgba(26, 33, 58, 0.37)',
        hint: 'rgba(26, 33, 58, 0.35)',
      },
    },
  },
  'proeex': {
    palette: {
      common: { black: 'rgba(26, 33, 58, 1)', white: '#fff' },
      background: { paper: '#fff', default: '#f8f8f8' },
      primary: {
        light: '#42a5f5',
        main: '#1976d2',
        dark: '#0d47a1',
        contrastText: '#fff',
      },
      secondary: {
        light: '#f06292',
        main: '#e91e63',
        dark: '#c2185b',
        contrastText: '#fff',
      },
      error: {
        light: 'rgba(230, 84, 84, 1)',
        main: 'rgba(254, 57, 57, 1)',
        dark: 'rgba(218, 51, 51, 1)',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(26, 33, 58, 0.95)',
        secondary: 'rgba(26, 33, 58, 0.61)',
        disabled: 'rgba(26, 33, 58, 0.37)',
        hint: 'rgba(26, 33, 58, 0.35)',
      },
    },
  },
  'vascoacademy': {
    palette: {
      common: { black: 'rgba(26, 33, 58, 1)', white: '#fff' },
      background: { paper: '#fff', default: '#f5f5f5' },
      primary: {
        light: 'rgba(69, 63, 59, 1)',   // Lighter version of the secondary color
        main: 'rgba(45, 41, 38, 1)',    // The exact RGB (45, 41, 38) converted to rgba
        dark: 'rgba(30, 27, 25, 1)',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(69, 63, 59, 1)',   // Lighter version of the secondary color
        main: 'rgba(45, 41, 38, 1)',    // The exact RGB (45, 41, 38) converted to rgba
        dark: 'rgba(30, 27, 25, 1)',    // Darker version of the secondary color
        contrastText: '#fff',
      },
      error: {
        light: 'rgba(230, 84, 84, 1)',
        main: 'rgba(254, 57, 57, 1)',
        dark: 'rgba(218, 51, 51, 1)',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(26, 33, 58, 0.95)',
        secondary: 'rgba(26, 33, 58, 0.61)',
        disabled: 'rgba(26, 33, 58, 0.37)',
        hint: 'rgba(26, 33, 58, 0.35)',
      },
    },
  },
  // Add more subdomains as needed
};

// Default Joga theme
export const defaultTheme: Theme = {
  palette: {
    common: { black: 'rgba(26, 33, 58, 1)', white: '#fff' },
    background: { paper: '#fff', default: '#fafafa' },
    primary: {
      light: 'rgba(159, 217, 98, 1)',
      main: 'rgba(106, 193, 15, 1)',
      dark: 'rgba(90, 163, 14, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(63, 120, 236, 1)',
      main: 'rgba(0, 84, 254, 1)',
      dark: 'rgba(11, 65, 174, 1)',
      contrastText: '#fff',
    },
    error: {
      light: 'rgba(230, 84, 84, 1)',
      main: 'rgba(254, 57, 1)',
      dark: 'rgba(218, 51, 51, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(26, 33, 58, 0.95)',
      secondary: 'rgba(26, 33, 58, 0.61)',
      disabled: 'rgba(26, 33, 58, 0.37)',
      hint: 'rgba(26, 33, 58, 0.35)',
    },
  },
};

// Utility to get current subdomain
const getCurrentSubdomain = (): string | null => {
  // For development environment
  if (process.env.NODE_ENV === 'development') {
    // You can change this to test different subdomains
    return 'furacao'; // or 'proeex' or null for default theme
  }

  if (typeof window === 'undefined') return null;

  const hostParts = window.location.hostname.split('.');
  if (hostParts.length > 2) {
    return hostParts[0];
  }
  return null;
};

// Create a provider component
export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [currentSubdomain, setCurrentSubdomain] = useState<string | null>(null);
  const [customTheme, setCustomTheme] = useState<Theme>(defaultTheme);

  useEffect(() => {
    const subdomain = getCurrentSubdomain();
    setCurrentSubdomain(subdomain);

    if (subdomain && subdomainThemes[subdomain]) {
      setCustomTheme(subdomainThemes[subdomain]);
    } else {
      setCustomTheme(defaultTheme);
    }
  }, []);

  const value: ThemeContextState = {
    customTheme,
    setCustomTheme,
    currentSubdomain
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom hook to use the ThemeContext
export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};
