import { useCallback } from 'react'

import { ReqBody, ResBody } from '../../../pages/api/auth/signin'
import useFetch, { FetchRequestState } from './useFetch'

const useSignIn = (): [
  (email: string, password: string) => void,
  FetchRequestState<ReqBody, ResBody>
] => {
  const [makeRequest, result] = useFetch<ReqBody, ResBody>([])

  const signIn = useCallback(
    (email: string, password: string) => {
      makeRequest('/api/auth/signin', 'POST', { email, password })
    },
    [makeRequest]
  )

  return [signIn, result]
}

export default useSignIn
