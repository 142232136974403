import { NextPage } from 'next'
import { useRouter } from 'next/router'
import React, { useCallback, useState, useEffect } from 'react'

import * as m from '@jogahq/lib/src/maybe'

import { isLoading, isSuccess } from '../../src/client/hooks/useFetch'
import useSignIn from '../../src/client/hooks/useSignIn'
import SignInLayout, { AuthError } from '../../src/client/layouts/Signin'
import { fetchAuthenticatedUserInfo } from '../../src/server/auth'
import { wrapGetServerSideProps } from '../../src/server/wrap-get-server-side-props'

const SignIn: NextPage = () => {
  const router = useRouter()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [signIn, signInResult] = useSignIn()

  const onSignIn: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      signIn(email, password)
    },
    [email, password]
  )

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setEmail(e.target.value)
    },
    []
  )

  const onChangePassword: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setPassword(e.target.value)
    }, [])

  let error: m.Maybe<AuthError> = m.none()
  if (isSuccess(signInResult)) {
    switch (signInResult.data.result) {
      case 'not-found':
        error = AuthError.NOT_FOUND
        break
      case 'password-missmatch':
        error = AuthError.PASSWORD_MISSMATCH
        break
      case 'not-allowed':
        error = AuthError.NOT_ALLOWED
        break
    }
  }

  useEffect(() => {
    if (!isSuccess(signInResult)) {
      return
    }

    if (signInResult.data.result === 'success') {
      router.reload()
    }
  }, [signInResult])

  const isRedirecting =
    isSuccess(signInResult) && signInResult.data.result === 'success'

  return (
    <SignInLayout
      email={email}
      onChangeEmail={onChangeEmail}
      password={password}
      onChangePassword={onChangePassword}
      onSignIn={onSignIn}
      error={error}
      loading={isLoading(signInResult) || isRedirecting}
    />
  )
}

export const getServerSideProps = wrapGetServerSideProps(
  async (context, loaders) => {
    const authInfo = await fetchAuthenticatedUserInfo(loaders)(
      context.req.session
    )

    if (m.isSome(authInfo)) {
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      }
    }

    return { props: {} }
  }
)

export default SignIn
